import { FC, useCallback } from "react";
import {
  Checkbox as AntdCheckbox,
  CheckboxProps as AntdCheckboxProps,
} from "antd";
import cn from "classnames";
import { CheckboxChangeEvent as AntCheckboxChangeEvent } from "antd/es/checkbox";
import styles from "./Checkbox.module.scss";
import { Text } from "../Text/Text";

export interface CheckboxChangeEvent extends AntCheckboxChangeEvent {}

export interface CheckboxProps extends AntdCheckboxProps {
  children: string;
  onUpdate?: (checked: boolean) => void;
}

export const Checkbox: FC<CheckboxProps> = ({
  className,
  children,
  onUpdate,
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => {
      if (onUpdate) {
        onUpdate(e.target.checked);
      }
      if (onChange) {
        onChange(e);
      }
    },
    [onChange, onUpdate],
  );

  return (
    <AntdCheckbox
      {...props}
      onChange={handleChange}
      className={cn(styles.checkbox, className)}
    >
      <Text variant="body-5">{children}</Text>
    </AntdCheckbox>
  );
};
