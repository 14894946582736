import { FC } from "react";
import Sider from "antd/es/layout/Sider";
import cn from "classnames";
import { Drawer } from "src/ui/Drawer/Drawer";
import s from "./MainLayoutUser.module.scss";
import { MainLayoutUserMenu } from "./components/MainLayoutUserMenu/MainLayoutUserMenu";

interface MainLayoutUserProps {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
  setWrapperRef: (element: HTMLElement | null) => void;
  isTablet: boolean;
}

export const MainLayoutUser: FC<MainLayoutUserProps> = ({
  collapsed,
  onCollapse,
  setWrapperRef,
  isTablet,
}) =>
  isTablet ? (
    <Drawer
      width={230}
      placement="right"
      visible={!collapsed}
      onClose={() => onCollapse(true)}
    >
      <span ref={setWrapperRef}>
        <MainLayoutUserMenu onCollapse={onCollapse} />
      </span>
    </Drawer>
  ) : (
    <Sider
      ref={setWrapperRef}
      className={cn(s.sider, { [s.sider_collapsed]: collapsed })}
      defaultCollapsed
      width={320}
      collapsedWidth={0}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <MainLayoutUserMenu onCollapse={onCollapse} />
    </Sider>
  );
