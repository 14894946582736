/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
interface EnvType {
  REACT_APP_THEME: string;
}
export const env: EnvType = { ...import.meta.env, ...window.env };
