import { env } from "src/utils/env";
import { ReactComponent as KretchetMobileLight } from "src/assets/logo_kretchet_mobile_light.svg";
import { ReactComponent as KretchetMobileDark } from "src/assets/logo_kretchet_mobile_dark.svg";
import { ReactComponent as KretchetDesktopLight } from "src/assets/logo_kretchet_desktop_light.svg";
import { ReactComponent as KretchetDesktopDark } from "src/assets/logo_kretchet_desktop_dark.svg";
import { ReactComponent as Odyssey } from "src/assets/logo_odyssey.svg";
import { FC, SVGProps } from "react";
import { Device, Theme } from "./types";

const mapAppThemeToIcon: Record<
  string,
  Record<
    Device,
    Record<
      Theme,
      {
        Icon: FC<SVGProps<SVGSVGElement>>;
        path: string;
      }
    >
  >
> = {
  kretchet: {
    mobile: {
      light: {
        Icon: KretchetMobileLight,
        path: "src/assets/logo_kretchet_mobile_light.svg",
      },
      dark: {
        Icon: KretchetMobileDark,
        path: "src/assets/logo_kretchet_mobile_dark.svg",
      },
    },
    desktop: {
      light: {
        Icon: KretchetDesktopLight,
        path: "src/assets/logo_kretchet_desktop_light.svg",
      },
      dark: {
        Icon: KretchetDesktopDark,
        path: "src/assets/logo_kretchet_desktop_dark.svg",
      },
    },
  },
  odyssey: {
    mobile: {
      light: {
        Icon: Odyssey,
        path: "src/assets/logo_odyssey.svg",
      },
      dark: {
        Icon: Odyssey,
        path: "src/assets/logo_odyssey.svg",
      },
    },
    desktop: {
      light: {
        Icon: Odyssey,
        path: "src/assets/logo_odyssey.svg",
      },
      dark: {
        Icon: Odyssey,
        path: "src/assets/logo_odyssey.svg",
      },
    },
  },
};

export const appIcon =
  mapAppThemeToIcon[env.REACT_APP_THEME] || mapAppThemeToIcon.odyssey;
