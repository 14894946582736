import React, { FC } from "react";
import { Flex } from "antd";
import { IoMdInformationCircle } from "react-icons/io";
import { useAlerts } from "@api/alerts/queries/useAlerts";
import cn from "classnames";
import { Text } from "../Text/Text";
import s from "./AlertsList.module.scss";

interface IAlertsListProps {
  maxVisible?: number;
}

const AlertsList: FC<IAlertsListProps> = ({ maxVisible }) => {
  const { data: alerts } = useAlerts({ limit: 100 });

  const visibleAlerts =
    alerts && (maxVisible ? alerts.slice(0, maxVisible) : alerts);

  return visibleAlerts && visibleAlerts.length > 0 ? (
    <Flex
      vertical
      gap={12}
      className={cn(!maxVisible && s.alertsListContainer)}
    >
      {visibleAlerts.map((alert) => (
        <Flex vertical gap={5} key={alert.id} className={s.alert}>
          <Flex className={s.alert_title} gap={8}>
            <IoMdInformationCircle className={s.alert_title_icon} />
            <Text variant="label-5">{alert.subject}</Text>
          </Flex>
          <Text variant="body-5" className={s.alert_message}>
            {alert.message}
          </Text>
        </Flex>
      ))}
    </Flex>
  ) : (
    <Flex className={s.noNewAlertsContainer} justify="center">
      <Text variant="label-5" className={s.noNewAlertsContainer__noNewAlerts}>
        Новых оповещений нет
      </Text>
    </Flex>
  );
};

export default AlertsList;
