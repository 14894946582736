import { FC, useCallback, useState } from "react";

import { LoginRequest } from "@api/auth";
import { Modal } from "antd";
import { AuthFormMode } from "./constants";
import { AuthFormLogin } from "./components/AuthFormLogIn/AuthFormLogIn";
import {
  AuthFormRegister,
  AuthFormRegisterFormValues,
} from "./components/AuthFormRegister/AuthFormRegister";
import { AuthFormChangePassword } from "./components/AuthFormChangePassword/AuthFormChangePassword";
import { AuthFormConfirmCode } from "./components/AuthFormConfirmCode/AuthFormConfirmCode";

interface AuthFormProps {
  onSuccess: (data?: AuthFormRegisterFormValues | LoginRequest) => void;
  onClose: () => void;
  modalVisible: boolean;
}

export const AuthForm: FC<AuthFormProps> = ({
  onSuccess,
  onClose,
  modalVisible,
}) => {
  const [mode, setMode] = useState<AuthFormMode>(AuthFormMode.LogIn);

  const onRegister = useCallback(() => {
    setMode(AuthFormMode.Register);
  }, []);
  const onChooseAuthorize = useCallback(() => {
    setMode(AuthFormMode.LogIn);
  }, []);
  const onChangePassword = useCallback(() => {
    setMode(AuthFormMode.ChangePassword);
  }, []);
  const onConfirmCode = useCallback(() => {
    setMode(AuthFormMode.ConfirmCode);
  }, []);

  const handleSuccess = useCallback(
    (data?: AuthFormRegisterFormValues | LoginRequest) => {
      setMode(AuthFormMode.LogIn);
      onSuccess(data);
    },
    [onSuccess],
  );

  return (
    <Modal open={modalVisible} closable={false} footer={null}>
      {mode === AuthFormMode.LogIn && (
        <AuthFormLogin
          onSuccess={handleSuccess}
          onRegister={onRegister}
          onChangePassword={onChangePassword}
          onClose={onClose}
        />
      )}
      {mode === AuthFormMode.Register && (
        <AuthFormRegister
          onChooseAuthorize={onChooseAuthorize}
          onSuccess={handleSuccess}
          onClose={onClose}
          onBack={onChooseAuthorize}
        />
      )}
      {mode === AuthFormMode.ChangePassword && (
        <AuthFormChangePassword onSuccess={onConfirmCode} onClose={onClose} />
      )}
      {mode === AuthFormMode.ConfirmCode && (
        <AuthFormConfirmCode
          onSuccess={onConfirmCode}
          email="lol"
          password="kek"
        />
      )}
    </Modal>
  );
};
