export const validationBounds = {
  Day: {
    Min: 1,
    Max: 31,
  },
  Month: {
    Min: 1,
    Max: 12,
  },
  Year: {
    Min: 1900,
    Max: 2099,
  },
  Age: {
    Min: 0,
    Max: 150,
  },
  Vin: {
    Length: 17,
  },
  Inn: {
    Length: 12,
  },
} as const;

export const validationMessage = {
  Required: "Обязательное поле",
  RequiredPolicy: "Необходимо принять условия",
  WrongLastName: "Можно использовать только буквы, цифры, дефис и тире",
  WrongMiddleName: "Можно использовать только буквы, цифры, дефис и тире",
  WrongFirstName: "Можно использовать только буквы, цифры, дефис и тире",
  WrongKadNumber: "Некорректный кадастровый номер",
  WrongEmail: "E-mail неверный формат",
  WrongPhone: "Телефон указан неверно",
  WrongInn: "Длина ИНН 12 цифр",
  WrongOgrn: "Некорректный ОГРН",
  WrongSnils: "Некорректный СНИЛС",
  WrongAutoNumber: "Неверный формат",
  WrongVin: "Неверный формат",
  WrongDriverLicense: "Длина ВУ не более 10 цифр",
  WrongPassport: "Длина серии и номера паспорта 10 цифр",
  WrongPassword: "Неверный пароль",
  WrongBirthday: "Неверный формат дата рождения",
  MinNumber: ({ min }: { min: number }) =>
    `Значение должно быть не меньше ${String(min)}`,
  MaxNumber: ({ max }: { max: number }) =>
    `Значение должно быть не больше ${String(max)}`,
  MinLen: ({ min }: { min: number }) =>
    `Значение должно быть не короче ${String(min)} символов`,
  MaxLen: ({ max }: { max: number }) =>
    `Значение должно быть не длинне ${String(max)} символов`,
  BirthDay: "Введите валидный день месяца",
  BirthMonth: `Введите валидный месяц (от ${validationBounds.Month.Min} до ${validationBounds.Month.Max})`,
  BirthYear: `Введите валидный год (от ${validationBounds.Year.Min} до ${validationBounds.Year.Max})`,
  Age: `Введите валидный возраст (от ${validationBounds.Age.Min} до ${validationBounds.Age.Max})`,
};

export const validationRegex = {
  /**
   * Регулярное выражение для проверки верхнего регистра, цифр и символов
   * WARNING нужно засинкать с значением по этому тегу PASSWORD-GENERATE
   */
  password: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
  phone: /^\+7\d{10}$/,
  letters: /^[\u0400-\u04FF- ]+$/,
  lettersSpacesHyphenDash: /^[A-Za-zА-Яа-яЁё\-–‐—\s]+$/,
  lettersSpacesHyphenDashExcept: /[^A-Za-zА-Яа-яЁё\-–‐—\s]+$/g,
  digits: /^\d+$/,
  kadNumber: /^\d{2}:\d{2}:\d{7}:\d{4}$/,
  autoNumber: /^[АВЕКМНОРСТУХ]{1,2}\d{3,4}[АВЕКМНОРСТУХ]{1,2}\d{2,3}$/,
  vin: /^[A-Z0-9]+$/,
  passport: /^\d{4} \d{6}$/,
  snils: /^\d{3}-\d{3}-\d{3} \d{2}$/,
  date: /\b(\d{2}\.?){1,2}(\d{4})?\b/,
};
