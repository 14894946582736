import { MouseEventHandler, FC, ReactNode, useCallback } from "react";
import { Flex } from "antd";
import cn from "classnames";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { Button, ButtonProps } from "src/ui/Button/Button";
import { Text } from "src/ui/Text/Text";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa6";
import s from "./CommonModal.module.scss";

type ModalType = "normal" | "success" | "error" | "space-around";

const mapTypeToIcon: Record<ModalType, ReactNode> = {
  "space-around": null,
  normal: null,
  success: <FaCheck size={48} />,
  error: <RxCross2 size={48} />,
};

interface CommonModalProps {
  type: ModalType;
  icon?: ReactNode;
  className?: string;
  iconSize?: number;
  iconWrapperClassName?: string;
  title: ReactNode;
  message?: ReactNode;
  body?: ReactNode;
  onClose?: () => void;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  buttonsVertical?: boolean;
  buttonsGap?: number;
}

export const CommonModal: FC<CommonModalProps> = ({
  type,
  icon,
  className,
  iconSize = 80,
  iconWrapperClassName,
  title,
  message,
  body,
  onClose,
  primaryButtonProps,
  secondaryButtonProps,
  buttonsVertical,
  buttonsGap = 24,
}) => {
  const { closeModal } = useModalMessage();
  const [primaryButton, secondaryButton] = buttonsVertical
    ? [secondaryButtonProps, primaryButtonProps]
    : [primaryButtonProps, secondaryButtonProps];

  const handlePrimary = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      primaryButton?.onClick?.(e);
      onClose?.();
      closeModal();
    },
    [primaryButton, onClose, closeModal],
  );

  const handleSecondary = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      secondaryButton?.onClick?.(e);
      onClose?.();
      closeModal();
    },
    [secondaryButton, onClose, closeModal],
  );

  const isPlainMessage = typeof message === "string";

  const iconNode = icon || mapTypeToIcon[type];

  return (
    <Flex
      vertical
      gap={32}
      className={cn(
        s.modal,
        { [s.modal_without_button]: !primaryButton && !secondaryButton },
        className,
      )}
    >
      {iconNode ? (
        <Flex justify="center" align="center">
          <Flex
            className={cn(iconWrapperClassName, s.icon_wrapper, {
              [s[`icon_wrapper_${type}`]]: !!mapTypeToIcon[type],
            })}
            align="center"
            justify="center"
            style={{ height: iconSize, width: iconSize }}
          >
            {iconNode}
          </Flex>
        </Flex>
      ) : null}
      <Flex vertical gap={16}>
        <Text align="center" variant="header-6">
          {title}
        </Text>
        {message ? (
          <Text
            variant="body-5"
            className={isPlainMessage ? undefined : s.message}
          >
            {message}
          </Text>
        ) : null}
        {body}
      </Flex>
      <Flex
        className={s.buttons}
        gap={buttonsGap}
        justify="flex-end"
        vertical={buttonsVertical}
      >
        {secondaryButton && (
          <Button
            type="flat_secondary"
            size="s"
            // eslint-disable-next-line react/no-children-prop
            children="Отмена"
            {...secondaryButton}
            onClick={handleSecondary}
          />
        )}
        {primaryButton && (
          <Button
            type="primary"
            size="s"
            // eslint-disable-next-line react/no-children-prop
            children="Хорошо"
            {...primaryButton}
            onClick={handlePrimary}
          />
        )}
      </Flex>
    </Flex>
  );
};
