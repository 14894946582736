import { FC, memo, useLayoutEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { env } from "src/utils/env";
import { router } from "src/app/routes/router";
import { AppThemeProvider } from "./components/AppTheme";
import { useAppFavicon } from "./hooks/useAppFavicon";

export const App: FC = memo(() => {
  useLayoutEffect(() => {
    document.documentElement.dataset.theme = env.REACT_APP_THEME;
  }, []);

  useAppFavicon();

  return (
    <AppThemeProvider>
      <RouterProvider router={router} />
    </AppThemeProvider>
  );
});

App.displayName = "App";
