import {
  FC,
  PropsWithChildren,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Theme } from "@processes/App/types";
import { AppThemeContext } from "./AppThemeContext";
import { UseAppThemeReturn } from "./types";
import { getUserAppTheme } from "./utils/getUserAppTheme";
import { saveUserAppTheme } from "./utils/saveUserAppTheme";

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(() => getUserAppTheme());

  useLayoutEffect(() => {
    document.documentElement.setAttribute("data-theme-mode", theme);

    saveUserAppTheme(theme);
  }, [theme]);

  const value = useMemo<UseAppThemeReturn>(
    () => [theme, setTheme],
    [theme, setTheme],
  );

  return (
    <AppThemeContext.Provider value={value}>
      {children}
    </AppThemeContext.Provider>
  );
};
