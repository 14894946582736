import { FC, ReactNode, useMemo } from "react";
import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { Link } from "react-router-dom";
import s from "./SearchItem.module.scss";

interface SearchItemProps {
  title: string;
  icon?: ReactNode;
  description?: string;
  href: string;
  onClick: () => void;
}

export const SearchItem: FC<SearchItemProps> = ({
  title,
  icon,
  description,
  href,
  onClick,
}) => {
  const searchItemContent = useMemo(
    () => (
      <Flex className={s.item__content} gap={10}>
        {icon}
        <Flex vertical>
          <Text variant="header-2">{title}</Text>
          {description && (
            <Text variant="body-5" color="secondary">
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
    ),
    [description, icon, title],
  );

  return /^https?:\/\//.test(href) ? (
    <a
      href={href}
      className={s.item}
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      {searchItemContent}
    </a>
  ) : (
    <Link to={href} className={s.item} onClick={onClick}>
      {searchItemContent}
    </Link>
  );
};
