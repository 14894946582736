import { CurrentUser } from "@api/groups";
import dayjs from "dayjs";

const ONE_MONTH_MS = 2_592_000_000;

export const getIsExpireSoon = (user: CurrentUser) => {
  const nextMonthMs = Date.now() + ONE_MONTH_MS;
  const nextMonthSeconds = Math.ceil(nextMonthMs / 1_000);

  const expiresAtNum = dayjs(user.expires_at, "DD.MM.YYYY hh:mm:ss").unix();
  const expiresAt = Number.isInteger(expiresAtNum)
    ? expiresAtNum
    : nextMonthSeconds;

  return expiresAt < nextMonthSeconds;
};
