import { TelegramUserBotRetrieveAllRequest } from "./telegram/types";

export const QueryKey = {
  TelegramUserBotAll: (
    { limit, offset }: TelegramUserBotRetrieveAllRequest = { limit: 100 },
  ) => ["TelegramUserBot", `limit:${limit}`, `offset:${offset}`],
  TelegramUserBotAllClear: () => ["TelegramUserBot"],
};

export const REFETCH_INTERVAL = 5000;
