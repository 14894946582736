import { FC } from "react";
import Sider from "antd/es/layout/Sider";
import { Drawer } from "src/ui/Drawer/Drawer";
import s from "./MainLayoutAside.module.scss";
import { MainLayoutAsideMenu } from "./components/MainLayoutAsideMenu/MainLayoutAsideMenu";

interface MainLayoutAsideProps {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
  isTablet: boolean;
}

export const MainLayoutAside: FC<MainLayoutAsideProps> = ({
  collapsed,
  onCollapse,
  isTablet,
}) =>
  isTablet ? (
    <Drawer
      width={230}
      placement="left"
      visible={!collapsed}
      onClose={() => onCollapse(true)}
    >
      <MainLayoutAsideMenu collapsed={collapsed} onCollapse={onCollapse} />
    </Drawer>
  ) : (
    <Sider
      className={s.sider}
      defaultCollapsed
      width={230}
      collapsedWidth={80}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <MainLayoutAsideMenu collapsed={collapsed} />
    </Sider>
  );
