import { useQuery } from "@tanstack/react-query";
import { ReportStatus } from "../constants";
import { REFETCH_INTERVAL } from "../../constants";
import {
  reportsAddressRetrieve,
  reportsCreditRatingRetrieve,
  reportsDriverLicenseRetrieve,
  reportsEmailRetrieve,
  reportsFioRetrieve,
  reportsFullCreditRatingRetrieve,
  reportsGrnRetrieve,
  reportsInfoRetrieve,
  reportsInnFlRetrieve,
  reportsInnUlRetrieve,
  reportsKadNumberRetrieve,
  reportsOgrnRetrieve,
  reportsPassportRetrieve,
  reportsPhoneRetrieve,
  reportsPhotoByFaceRetrieve,
  reportsPhotoByFioRetrieve,
  reportsRetrieveAll,
  reportsScoringRetrieve,
  reportsSnilsRetrieve,
  reportsVinRetrieve,
} from "./queries";
import { ReportsRetrieveAllRequest, ReportsRetrieveRequest } from "./request";

export const useReportsFioRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsFioRetrieve", "reports", data?.id],
    queryFn: () => reportsFioRetrieve(data),
    enabled: Boolean(data.id),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsPhoneRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsPhoneRetrieve", "reports", data?.id],
    queryFn: () => reportsPhoneRetrieve(data),
    enabled: Boolean(data.id),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsAddressRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsAddressRetrieve", "reports", data?.id],
    queryFn: () => reportsAddressRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsKadNumberRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsKadNumberRetrieve", "reports", data?.id],
    queryFn: () => reportsKadNumberRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsInnFlRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsInnFlRetrieve", "reports", data?.id],
    queryFn: () => reportsInnFlRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsSnilsRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsSnilsRetrieve", "reports", data?.id],
    queryFn: () => reportsSnilsRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsPassportRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsPassportRetrieve", "reports", data?.id],
    queryFn: () => reportsPassportRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsGrnRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsGrnRetrieve", "reports", data?.id],
    queryFn: () => reportsGrnRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsVinRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsVinRetrieve", "reports", data?.id],
    queryFn: () => reportsVinRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsDriverLicenseRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsDriverLicenseRetrieve", "reports", data?.id],
    queryFn: () => reportsDriverLicenseRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsEmailRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsEmailRetrieve", "reports", data?.id],
    queryFn: () => reportsEmailRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsInnUlRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsInnUlRetrieve", "reports", data?.id],
    queryFn: () => reportsInnUlRetrieve(data),
  });

export const useReportsOgrnRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsOgrnRetrieve", "reports", data?.id],
    queryFn: () => reportsOgrnRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsPhotoByFaceRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsPhotoByFaceRetrieve", "reports", data?.id],
    queryFn: () => reportsPhotoByFaceRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsPhotoByFioRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsPhotoByFioRetrieve", "reports", data?.id],
    queryFn: () => reportsPhotoByFioRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsCreditRatingRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsCreditRatingRetrieve", "reports", data?.id],
    queryFn: () => reportsCreditRatingRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsFullCreditRatingRetrieve = (
  data: ReportsRetrieveRequest,
) =>
  useQuery({
    queryKey: ["reportsFullCreditRatingRetrieve", "reports", data?.id],
    queryFn: () => reportsFullCreditRatingRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsScoringRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsScoringRetrieve", "reports", data?.id],
    queryFn: () => reportsScoringRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsInfoRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryKey: ["reportsInfoRetrieve", "reports", data?.id],
    queryFn: () => reportsInfoRetrieve(data),
    refetchInterval:
      data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsRetrieveAll = (
  data: ReportsRetrieveAllRequest,
  { enabled }: { enabled?: boolean } = {},
) =>
  useQuery({
    queryKey: [
      "reports",
      `limit:${data.limit}`,
      `offset:${data.offset}`,
      `request_contains:${data.request_contains}`,
      `report_task_types:${data.report_task_types?.join()}`,
      `order_by:${data.order_by}`,
    ],
    queryFn: () => reportsRetrieveAll(data),
    enabled,
    refetchInterval: data?.isInProgress ? REFETCH_INTERVAL : false,
  });
