import React from "react";

export const Report = React.lazy(() => import("./Report/Report"));
export const Main = React.lazy(() => import("./Main/Main"));
export const Admin = React.lazy(() => import("./Admin/Admin"));
export const UserSettings = React.lazy(
  () => import("./UserSettings/UserSettings"),
);
export const Analytics = React.lazy(() => import("./Analytics/Analytics"));
export const Employees = React.lazy(() => import("./Employees/Employees"));
export const Accesses = React.lazy(() => import("./Accesses/Accesses"));
export const AccessesAdd = React.lazy(
  () => import("./AccessesAdd/AccessesAdd"),
);
export const AccessesEdit = React.lazy(
  () => import("./AccessesEdit/AccessesEdit"),
);
export const Search = React.lazy(() => import("./Search/Search"));
export const Telegram = React.lazy(() => import("./Telegram/Telegram"));
export const History = React.lazy(() => import("./History/History"));
export const Contacts = React.lazy(() => import("./Contacts/Contacts"));
export const Alerts = React.lazy(() => import("./Alerts/Alerts"));
export const Documents = React.lazy(() => import("./Documents/Documents"));
export const SupportChat = React.lazy(
  () => import("./SupportChat/SupportChat"),
);
export const ActionLog = React.lazy(() => import("./ActionLog/ActionLog"));
export const Tariffs = React.lazy(() => import("./Tariffs/Tariffs"));
