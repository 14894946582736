import { FC, useCallback } from "react";
import { Alert, Flex } from "antd";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { UserCard } from "src/components/UserCard/UserCard";
import { Text } from "src/ui/Text/Text";
import { ReactComponent as Error } from "src/assets/error.svg";
import { Button } from "src/ui/Button/Button";
import { AppRoute } from "src/app/routes/constants";
import { Link } from "react-router-dom";
import { useLogout } from "@api/auth/mutations/useLogout";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";
import { toHumanReadableDate } from "src/utils/date/toHumanReadableDate";
import dayjs from "dayjs";
import { getPluralRule } from "src/utils/getPluralRule";
import s from "./MainLayoutUserMenu.module.scss";
import { getIsExpireSoon } from "./utils/getIsExpireSoon";

const mapPluralRuleToLabel: Record<Intl.LDMLPluralRule, string> = {
  zero: "дней",
  one: "день",
  two: "дня",
  few: "дней",
  many: "дней",
  other: "дней",
};

interface MainLayoutUserMenuProps {
  onCollapse: (collapsed: boolean) => void;
}

export const MainLayoutUserMenu: FC<MainLayoutUserMenuProps> = ({
  onCollapse,
}) => {
  const { data: user } = useCurrentUserQuery();
  const { mutateAsync: logout, isPending: isLogoutPending } = useLogout();

  const [api] = useNotification();

  const onClose = useCallback(() => {
    onCollapse(true);
  }, [onCollapse]);

  const onLogout = useCallback(() => {
    logout({})
      .then(() => {
        onClose();
      })
      .catch((error) => {
        handleBackendErrors({
          api,
          error,
          message4xx: "Не удалось удалить пользователя",
        });
      });
  }, [api, logout, onClose]);

  if (!user) {
    return null;
  }

  const expiresAtDayjs = dayjs(user.expires_at, "DD.MM.YYYY hh:mm:ss");
  const diffDays = expiresAtDayjs.diff(undefined, "days");
  const pluralRule = getPluralRule(diffDays);

  return (
    <Flex vertical gap={16} className={s.menu}>
      <Link to={AppRoute.User} className={s.menu__link} onClick={onClose}>
        <UserCard user={user} type="flat" size="s" />
      </Link>
      {getIsExpireSoon(user) ? (
        <Alert
          icon={<Error height={12} width={12} />}
          showIcon
          type="error"
          message={
            <Flex vertical>
              <Text variant="label-1">Истекает срок договора</Text>
              <Text variant="body-1">
                Осталось {diffDays}{" "}
                {mapPluralRuleToLabel[pluralRule] || mapPluralRuleToLabel.other}
                . До {toHumanReadableDate(expiresAtDayjs.toISOString())}
              </Text>
            </Flex>
          }
        />
      ) : null}
      <Flex vertical className={s.menu__card} gap={8}>
        <Flex vertical gap={4}>
          <Text color="secondary" variant="body-1">
            Остаток проверок на балансе
          </Text>
          {user.request_quota ? (
            <Text variant="label-3">
              до {user.request_quota - user.request_usage} проверок
            </Text>
          ) : (
            <Text variant="label-3">Безлимит</Text>
          )}
        </Flex>
        <Flex vertical gap={4}>
          <Text color="secondary" variant="body-1">
            Совершено проверок
          </Text>
          <Flex gap={4} component="span" align="baseline">
            <Text variant="subheader-4">{user.request_usage}</Text>
            <Text variant="body-1">стандартных проверок</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex vertical gap={12} className={s.menu__footer}>
        <Link
          to={AppRoute.Documents}
          className={s.menu__link}
          onClick={onClose}
        >
          <Text variant="body-5">Мои документы</Text>
        </Link>
        <Link to={AppRoute.User} className={s.menu__link} onClick={onClose}>
          <Text variant="body-5">Аккаунт и безопасность</Text>
        </Link>
        <Button
          type="text"
          onClick={onLogout}
          disabled={isLogoutPending}
          className={s.menu__button}
        >
          <Text variant="body-5">Выход</Text>
        </Button>
      </Flex>
    </Flex>
  );
};
