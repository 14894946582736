import { TextColor } from "../../ui/Text/Text";

const warningTitleKeys = [
  "нарушители",
  "правошарушения",
  "административные",
  " апн ",
  "АНТИКРЕДИТОРЫ",
  "ГАИ ПДД РОССИЯ",
  "СПБ КРИМИНАЛ САНКТ-ПЕТЕРБУРГ АДМ ПРАВ",
];
const redTitleKeys = [
  "розыск",
  "судим",
  "криминал",
  "сводки",
  "осужденные",
  "учет",
  "зиц",
  "уголовные",
  "мошенник",
  "наркоманы",
  "покинувшие",
  "метки",
];

export const getSourceTitleColor = (title: string): TextColor | undefined => {
  const lowerTitle = title.toLowerCase();

  if (warningTitleKeys.some((key) => lowerTitle.includes(key.toLowerCase()))) {
    return "warning";
  }

  if (redTitleKeys.some((key) => lowerTitle.includes(key.toLowerCase()))) {
    return "red";
  }

  return undefined;
};
