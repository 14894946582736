import { createBrowserRouter, Navigate } from "react-router-dom";
import React, { Suspense } from "react";
import {
  Report,
  Main,
  Admin,
  UserSettings,
  Analytics,
  Employees,
  Accesses,
  AccessesAdd,
  AccessesEdit,
  Search,
  Telegram,
  History,
  Contacts,
  Alerts,
  Documents,
  SupportChat,
  ActionLog,
  Tariffs,
} from "@pages";
import Root from "@pages/Root/Root";
import { Skeleton } from "antd";
import { AppRoute } from "./constants";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";

export const router = createBrowserRouter([
  {
    path: AppRoute.Root,
    element: <Root />,
    children: [
      {
        path: "",
        element: <Navigate to={AppRoute.Lk} replace />,
      },
      {
        path: AppRoute.Report,
        element: (
          <Suspense fallback={<Skeleton />}>
            <Report />
          </Suspense>
        ),
      },
      {
        path: AppRoute.Lk,
        element: (
          <Suspense fallback={<Skeleton />}>
            <Main />
          </Suspense>
        ),
      },
      {
        path: AppRoute.Lk,
        element: <PrivateRoute />,
        children: [
          {
            path: AppRoute.Admin,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Admin />
              </Suspense>
            ),
          },
          {
            path: AppRoute.User,
            element: (
              <Suspense fallback={<Skeleton />}>
                <UserSettings />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Analytics,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Analytics />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Employees,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Employees />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Accesses,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Accesses />
              </Suspense>
            ),
          },
          {
            path: AppRoute.AccessesAdd,
            element: (
              <Suspense fallback={<Skeleton />}>
                <AccessesAdd />
              </Suspense>
            ),
          },
          {
            path: AppRoute.AccessesEdit,
            element: (
              <Suspense fallback={<Skeleton />}>
                <AccessesEdit />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Search,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Search />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Telegram,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Telegram />
              </Suspense>
            ),
          },
          {
            path: AppRoute.History,
            element: (
              <Suspense fallback={<Skeleton />}>
                <History />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Contacts,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Contacts />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Alerts,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Alerts />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Documents,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Documents />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Support,
            element: (
              <Suspense fallback={<Skeleton />}>
                <SupportChat />
              </Suspense>
            ),
          },
          {
            path: AppRoute.Action,
            element: (
              <Suspense fallback={<Skeleton />}>
                <ActionLog />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: AppRoute.Tariffs,
        element: (
          <Suspense fallback={<Skeleton />}>
            <Tariffs />
          </Suspense>
        ),
      },
    ],
  },
]);
