import React from "react";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import s from "./MainLayoutAlerts.module.scss";
import { Text } from "../../../../../ui/Text/Text";
import { AppRoute } from "../../../../routes/constants";
import AlertsList from "../../../../../ui/AlertsList/AlertsList";

const MainLayoutAlerts = ({ hideAlerts }: { hideAlerts: () => void }) => {
  const navigate = useNavigate();

  const handleShowAll = () => {
    hideAlerts();
    navigate(AppRoute.Alerts);
  };

  return (
    <Flex
      vertical
      gap={16}
      className={s.alert}
      onClick={(e) => e.stopPropagation()}
    >
      <Text variant="label-5">Системные оповещения</Text>
      <Flex vertical gap={16} className={s.alert__content}>
        <AlertsList maxVisible={6} />
        <Flex
          justify="center"
          className={s.alert__content__showAllButtonContainer}
        >
          <Text
            variant="subheader-2"
            color="accent"
            className={s.alert__content__showAllButtonContainer__showAllButton}
            onClick={() => handleShowAll()}
          >
            Показать все
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MainLayoutAlerts;
