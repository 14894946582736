import { DefaultRequestObjectEntry } from "../types";

type DatePrefix = string;
enum DatePostfix {
  Day = "day",
  Month = "month",
  Year = "year",
}

const dateDelimiter = "_";

type DefaultRequestObjectEntryByDate = [
  `${DatePrefix}${typeof dateDelimiter}${DatePostfix}`,
  string | number,
];

type DefaultRequestObjectEntryByPostfix = [DatePostfix, string | number];

const isDate = (
  entry: DefaultRequestObjectEntry,
): entry is DefaultRequestObjectEntryByDate => {
  const [key, value] = entry;

  if (key.endsWith(`${dateDelimiter}${DatePostfix.Year}`)) {
    if (typeof value === "string") {
      return value.length <= 4;
    }
    if (typeof value === "number") {
      return true;
    }
    return false;
  }

  if (key.endsWith(`${dateDelimiter}${DatePostfix.Month}`)) {
    if (typeof value === "string") {
      return true;
    }
    if (typeof value === "number") {
      return value <= 12;
    }
    return false;
  }

  if (key.endsWith(`${dateDelimiter}${DatePostfix.Day}`)) {
    if (typeof value === "string") {
      return value.length <= 2;
    }
    if (typeof value === "number") {
      return value <= 31;
    }
    return false;
  }

  return false;
};

const mapDatePostfixToOrder: Record<DatePostfix, number> = {
  day: 2,
  month: 1,
  year: 0,
};

const mapDatePostfixToMinLength: Record<DatePostfix, number> = {
  day: 2,
  month: 2,
  year: 4,
};

export const reduceDefaultRequestObjectDates = (
  entries: DefaultRequestObjectEntry[],
): DefaultRequestObjectEntry[] => {
  const result: DefaultRequestObjectEntry[] = [];
  const dateEntriesMap: Record<
    DatePrefix,
    DefaultRequestObjectEntryByPostfix[]
  > = {};

  entries.forEach((entry) => {
    if (!isDate(entry)) {
      result.push(entry);
      return;
    }

    const [key, value] = entry;
    const temp = key.split(dateDelimiter);
    const prefix: DatePrefix = temp[0];
    const postFix: DatePostfix = temp[1] as DatePostfix;

    if (prefix) {
      dateEntriesMap[prefix] ||= [];
      dateEntriesMap[prefix].push([postFix, value]);
    }
  });

  Object.entries(dateEntriesMap).forEach(([prefix, prefixEntries]) => {
    prefixEntries.sort(([aKey], [bKey]) => {
      const postFixA = aKey.split(dateDelimiter)[1];
      const postFixB = bKey.split(dateDelimiter)[1];

      const orderA = mapDatePostfixToOrder[postFixA] || 0;
      const orderB = mapDatePostfixToOrder[postFixB] || 0;

      return orderB - orderA;
    });

    const joinedDate = prefixEntries
      .map(([key, value]) =>
        String(value).padStart(mapDatePostfixToMinLength[key], "0"),
      )
      .join(".");
    result.push([prefix, joinedDate]);
  });

  return result;
};
