import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Flex, Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { useMainTabSearchParam } from "@pages/Main/hooks/useMainTabSearchParam";
import { useGroups } from "@api/groups";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { Text } from "src/ui/Text/Text";
import { ENavMenuItem } from "@pages/Main/constants";
import { Button } from "src/ui/Button/Button";
import { getAvailableCheckTabs } from "src/utils/permissions/getAvailableCheckTabs";
import { getMenuItems, MenuGroup } from "../../../constants";
import s from "./MainLayoutAsideMenu.module.scss";

interface MainLayoutAsideMenuProps {
  collapsed: boolean;
  onCollapse?: (collapsed: boolean) => void;
}

export const MainLayoutAsideMenu: FC<MainLayoutAsideMenuProps> = ({
  collapsed,
  onCollapse,
}) => {
  const [activeKeys, setActiveKeys] = useState<Set<string>>(new Set());
  const [delayedKeys, setDelayedKeys] = useState<string[]>([]);
  const [tab] = useMainTabSearchParam();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    if (onCollapse) onCollapse(true);
  }, [onCollapse]);

  useLayoutEffect(() => {
    const newKeys = new Set(activeKeys);

    if (pathname === (AppRoute.Lk as string)) {
      newKeys.add(MenuGroup.Checking);
    }
    if (
      pathname.includes(ENavMenuItem.Analytics) ||
      pathname.includes(ENavMenuItem.Accesses)
    ) {
      newKeys.add(MenuGroup.Cabinet);
    }
    if (pathname === (AppRoute.History as string)) {
      newKeys.add(MenuGroup.Organization);
    }
    if (pathname.includes(ENavMenuItem.Contacts)) {
      newKeys.add(MenuGroup.Info);
    }

    setActiveKeys(newKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!collapsed) setDelayedKeys(Array.from(activeKeys));
    else setDelayedKeys([]);
  }, [collapsed, activeKeys]);

  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups[0].permissions;

  // TODO remove it after user always being in group
  const { data: groups } = useGroups();
  const group = groups?.[0];
  // TODO END remove it after backed

  const menuItems = useMemo(() => {
    const items = getMenuItems({
      group_id: group?.group_id,
      user_id: user?.id,
    });

    const filteredMainItems = getAvailableCheckTabs(
      items[0].children,
      userPermissions,
    );

    return [{ ...items[0], children: filteredMainItems }, ...items.slice(1)];
  }, [group?.group_id, user?.groups, user?.id]);

  return (
    <Flex vertical gap={16} className={s.menu}>
      <Menu
        mode="inline"
        openKeys={collapsed ? undefined : delayedKeys}
        inlineCollapsed={collapsed}
        selectedKeys={[tab]}
      >
        {menuItems.map((item) =>
          item.children ? (
            <Menu.SubMenu
              key={item.key}
              icon={item.icon}
              onTitleClick={() => {
                const newKeys = new Set(activeKeys);
                if (newKeys.has(item.key)) {
                  newKeys.delete(item.key);
                } else {
                  newKeys.add(item.key);
                }
                setActiveKeys(newKeys);
              }}
              title={
                <Text variant="body-5" color="inherit">
                  {item.title}
                </Text>
              }
            >
              {item.children.map((subItem) => (
                <Menu.Item className={s.item} key={subItem.key}>
                  <Button
                    type="flat"
                    size="s"
                    className={s.menu__submenu_button}
                    disabled={subItem.disabled}
                    onClick={() => {
                      if (/^https?:\/\//.test(subItem.href)) {
                        window.open(subItem.href);
                      } else {
                        navigate(subItem.href);
                      }
                      onClose();
                    }}
                  >
                    {subItem.title}
                  </Button>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={item.key}>
              <Link to={item.href!} onClick={onClose}>
                {collapsed ? null : <Text variant="body-5">{item.title}</Text>}
              </Link>
            </Menu.Item>
          ),
        )}
      </Menu>
    </Flex>
  );
};
