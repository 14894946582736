import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@api/client";
import { login } from "../queries";
import { LoginRequest } from "../types";

export const useLogin = (closeModal?: () => void) =>
  useMutation({
    mutationKey: ["login"],
    mutationFn: (data: LoginRequest) => login(data),
    onSuccess: async () => {
      closeModal?.();

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ["currentUser"] }),
        queryClient.invalidateQueries({ queryKey: ["reports"] }),
        queryClient.invalidateQueries({ queryKey: ["profilePicture"] }),
        queryClient.invalidateQueries({ queryKey: ["groups"] }),
        queryClient.invalidateQueries({ queryKey: ["alerts"] }),
        queryClient.invalidateQueries({ queryKey: ["employees"] }),
      ]);
    },
  });
