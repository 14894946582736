import { useCallback, useLayoutEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { ENavMenuItem, isMainTab, MAIN_TAB_QUERY, MainTab } from "../constants";

export type SetMainTabAction = (newState: MainTab) => void;

const MAIN_TAB_DEFAULT_KEY = "main_tab_default";

export const getDefaultMainTab = (): MainTab => {
  try {
    const defaultTabItem = localStorage.getItem(MAIN_TAB_DEFAULT_KEY);

    return isMainTab(defaultTabItem) ? defaultTabItem : MainTab.Fio;
  } catch {
    return MainTab.Fio;
  }
};

const setDefaultTab = (tab: MainTab | ENavMenuItem): void => {
  try {
    localStorage.setItem(MAIN_TAB_DEFAULT_KEY, tab);
    return undefined;
  } catch {
    return undefined;
  }
};

export const useMainTabSearchParam = (): readonly [
  tab: MainTab | ENavMenuItem,
  setTab: SetMainTabAction,
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const defaultTab = useMemo(() => getDefaultMainTab(), []);
  const tabQuery = searchParams.get(MAIN_TAB_QUERY);
  const tab = isMainTab(tabQuery)
    ? tabQuery
    : Object.values(ENavMenuItem).find((item) => pathname.includes(item)) ||
      null;

  const setTab = useCallback(
    (newTab: MainTab) => {
      setSearchParams((prevSearchParams) => ({
        ...prevSearchParams,
        tab: newTab,
      }));
    },
    [setSearchParams],
  );

  useLayoutEffect(() => {
    if (tab) {
      setDefaultTab(tab);
    }
  }, [tab]);

  useLayoutEffect(() => {
    if (pathname === String(AppRoute.Lk) && !tab) {
      setTab(defaultTab);
    }
  }, [setTab, tab, pathname, defaultTab]);

  return [tab || defaultTab, setTab];
};
