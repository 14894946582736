import { useQuery } from "@tanstack/react-query";
import { getAlerts } from "@api/alerts/queries";
import { IGetAlertsRequest } from "@api/alerts/types";

export const useAlerts = (data: IGetAlertsRequest) =>
  useQuery({
    queryKey: ["alerts"],
    queryFn: () => getAlerts(data),
    refetchOnMount: true,
  });
