import { Theme } from "@processes/App/types";
import { isTheme } from "./isTheme";
import { USER_APP_THEME_KEY_LS } from "./constants";

const defaultTheme = "light";

export const getUserAppTheme = (): Theme => {
  try {
    const savedTheme = localStorage.getItem(USER_APP_THEME_KEY_LS);
    if (!savedTheme) {
      return defaultTheme;
    }

    return isTheme(savedTheme) ? savedTheme : defaultTheme;
  } catch {
    return defaultTheme;
  }
};
