import { Flex, Image } from "antd";
import { FC, RefObject, useCallback, useState } from "react";
import { Text } from "src/ui/Text/Text";
import { Input } from "src/ui/Input/Input";
import { FaCircleUser, FaBell } from "react-icons/fa6";
import { Header } from "antd/es/layout/layout";
import { Button } from "src/ui/Button/Button";
import { IoMenu, IoClose, IoChevronBack } from "react-icons/io5";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { AuthForm } from "src/components/AuthForm/AuthForm";
import { Link } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { useUsersProfilePictureRetrieveQuery } from "@api/groups/mutations/useUsersProfilePictureRetrieveQuery";
import { useBooleanState } from "src/hooks/useBooleanState";
import classNames from "classnames";
import { debounce } from "lodash";
import { MdSearch } from "react-icons/md";
import {
  KrechetFragment,
  OdysseyFragment,
} from "src/components/ProjectFragments";
import { usePageSize } from "src/hooks/usePageSize";
import { useAppTheme } from "@processes/App/components/AppTheme";
import { appIcon } from "@processes/App/appIcon";
import s from "./MainLayoutHeader.module.scss";
import { MainLayoutHeaderSearch } from "./components/MainLayoutHeaderSearch/MainLayoutHeaderSearch";
import { MainLayoutThemeSwitcher } from "./components/MainLayoutThemeSwitcher/MainLayoutThemeSwitcher";

interface MainLayoutHeaderProps {
  userButtonRef: RefObject<HTMLButtonElement>;
  leftCollapsed: boolean;
  rightCollapsed: boolean;
  onLeftCollapse: (collapsed: boolean) => void;
  onRightCollapse: (collapsed: boolean) => void;
  alertsVisible: boolean;
  showAlerts: () => void;
  hideAlerts: () => void;
  isTablet: boolean;
  isMobile: boolean;
}

export const MainLayoutHeader: FC<MainLayoutHeaderProps> = ({
  userButtonRef,
  leftCollapsed,
  rightCollapsed,
  onLeftCollapse,
  onRightCollapse,
  alertsVisible,
  showAlerts,
  hideAlerts,
  isTablet,
  isMobile,
}) => {
  const [filter, setFilter] = useState("");
  const [filterUi, setFilterUi] = useState("");
  const {
    data: user,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
    isRefetching: isUserRefetching,
  } = useCurrentUserQuery();
  const {
    state: isAuthModalVisible,
    setTrue: showAuthModal,
    setFalse: hideAuthModal,
  } = useBooleanState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedFilterUpdate = useCallback(
    debounce((value: string) => {
      setFilter(value);
    }, 1000),
    [],
  );

  const onFilterUpdate = useCallback(
    (value: string) => {
      setFilterUi(value);
      onDebouncedFilterUpdate(value);
    },
    [onDebouncedFilterUpdate],
  );

  const {
    state: searchVisible,
    setTrue: showSearch,
    setFalse: hideSearch,
  } = useBooleanState();

  const hideSearchWithClear = useCallback(() => {
    hideSearch();
    onFilterUpdate("");
  }, [hideSearch, onFilterUpdate]);

  const toggleLeftCollapse = useCallback(() => {
    onLeftCollapse(!leftCollapsed);
  }, [leftCollapsed, onLeftCollapse]);

  const onUserClick = useCallback(() => {
    hideSearch();
    hideAlerts();

    if (user && isUserSuccess) {
      onRightCollapse(!rightCollapsed);
    } else {
      showAuthModal();
    }
  }, [
    hideSearch,
    hideAlerts,
    user,
    isUserSuccess,
    onRightCollapse,
    rightCollapsed,
    showAuthModal,
  ]);

  const { data: photo } = useUsersProfilePictureRetrieveQuery(
    { user_id: user?.id || 0 },
    { enabled: Boolean(user) },
  );

  const onSearchFocus = useCallback(() => {
    showSearch();
    hideAlerts();
  }, [showSearch, hideAlerts]);

  const onBellClick = useCallback(() => {
    hideSearch();

    if (alertsVisible) hideAlerts();
    else showAlerts();
  }, [alertsVisible, hideAlerts, hideSearch, showAlerts]);

  const [theme] = useAppTheme();
  const { device } = usePageSize();

  const AppIcon = appIcon[device][theme];

  const themeSwitcherEnabled = device === "desktop";

  return (
    <Header className={s.header}>
      <AuthForm
        onSuccess={hideAuthModal}
        onClose={hideAuthModal}
        modalVisible={isAuthModalVisible}
      />
      <Flex className={s.header__left_container} gap={32}>
        {searchVisible ? (
          <>
            <Flex align="center" justify="center" gap={16}>
              <Button
                type="flat"
                pin="round"
                icon={<IoChevronBack size={24} />}
                onClick={hideSearch}
              />
            </Flex>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div className={s.header__overlay} onClick={hideSearch} />
          </>
        ) : (
          <Flex align="center" justify="center" gap={16}>
            <Button
              type="flat"
              pin="round"
              title={leftCollapsed ? "menu" : "close"}
              icon={
                leftCollapsed ? <IoMenu size={24} /> : <IoClose size={24} />
              }
              onClick={toggleLeftCollapse}
            />
          </Flex>
        )}
      </Flex>
      {searchVisible ? null : (
        <Flex className={s.header__center_container}>
          <Link to={AppRoute.Lk} className={s.header__link}>
            <Flex align="center" justify="center" gap={8}>
              <KrechetFragment>
                <AppIcon.Icon width={isMobile ? 60 : 139} height={47} />
              </KrechetFragment>
              <OdysseyFragment>
                <AppIcon.Icon width={24} height={24} />
                {!isMobile && <Text variant="label-2">ODYSSEY SEARCH</Text>}
              </OdysseyFragment>
            </Flex>
          </Link>
        </Flex>
      )}
      <Flex
        className={classNames(s.header__right_container, {
          [s.header__right_container_wide]: searchVisible,
        })}
        gap={32}
      >
        <Flex vertical className={s.header__right_container__wrapper}>
          {isTablet && !searchVisible ? null : (
            <Input
              placeholder="Поиск по системе"
              placeholderIcon={<MdSearch size={20} />}
              value={filterUi}
              onUpdate={onFilterUpdate}
              onFocus={onSearchFocus}
              disabled={!user}
              autoFocus={searchVisible}
              className={s.header__right_container__wrapper_input}
            />
          )}
          {filter && searchVisible ? (
            <MainLayoutHeaderSearch
              query={filter}
              onShowItem={hideSearchWithClear}
              onShowAll={hideSearch}
            />
          ) : null}
        </Flex>
        <Flex align="center" justify="center" gap={isMobile ? 0 : 16}>
          {isTablet && !searchVisible && (
            <Button
              title="search"
              type="flat"
              pin="round"
              className={s.header__right_container__buttons_button}
              onClick={() => {
                showSearch();
                hideAlerts();
              }}
              disabled={!user}
              icon={<MdSearch size={24} />}
            />
          )}
          {themeSwitcherEnabled ? <MainLayoutThemeSwitcher /> : null}
          <Button
            onClick={onBellClick}
            title="bell"
            type="flat"
            pin="round"
            className={s.header__right_container__buttons_button}
            icon={<FaBell size={24} />}
          />
          <Button
            ref={userButtonRef}
            title="user"
            type="flat"
            pin="round"
            className={s.header__right_container__buttons_button}
            disabled={isUserLoading || isUserRefetching}
            onClick={onUserClick}
            icon={
              isUserSuccess && photo ? (
                <Image
                  rootClassName={s.header__avatar}
                  preview={false}
                  src={photo}
                  alt={`${String(user?.id)} avatar`}
                  height={24}
                  width={24}
                />
              ) : (
                <FaCircleUser size={24} />
              )
            }
          />
        </Flex>
      </Flex>
    </Header>
  );
};
