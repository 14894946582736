import {
  forwardRef,
  HTMLAttributeAnchorTarget,
  MouseEventHandler,
  ReactNode,
  RefObject,
} from "react";
import cs from "classnames";

import s from "./Button.module.scss";

export interface ButtonProps {
  children?: ReactNode;
  type?:
    | "primary"
    | "secondary"
    | "text"
    | "text_secondary"
    | "flat"
    | "flat_secondary";
  pin?: "normal" | "round" | "brick";
  size?: "l" | "m" | "s" | "xs";
  htmlType?: "submit" | "button";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  target?: HTMLAttributeAnchorTarget;
  link?: string;
  className?: string;
  icon?: ReactNode;
  iconRight?: ReactNode;
  width?: "full" | "fit";
  rel?: string;
  title?: string;
}

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      width,
      type = "primary",
      pin = "normal",
      size = "l",
      htmlType = "button",
      onClick,
      children,
      disabled,
      link,
      target,
      className,
      icon,
      iconRight,
      rel,
      title,
    },
    ref,
  ) =>
    link ? (
      <a
        ref={ref as undefined | RefObject<HTMLAnchorElement>}
        target={target}
        className={cs(
          className,
          s.button,
          {
            [s[`button_width_${width}`]]: width,
          },
          s[`button_size_${size}`],
          s[`button_type_${type}`],
          s[`button_pin_${pin}`],
        )}
        href={link}
        rel={rel}
        title={title}
      >
        {icon}
        {children}
        {iconRight}
      </a>
    ) : (
      <button
        ref={ref as undefined | RefObject<HTMLButtonElement>}
        disabled={disabled}
        onClick={onClick}
        type={htmlType === "button" ? "button" : "submit"}
        className={cs(
          className,
          s.button,
          {
            [s[`button_width_${width}`]]: width,
          },
          s[`button_size_${size}`],
          s[`button_type_${type}`],
          s[`button_pin_${pin}`],
        )}
        title={title}
      >
        {icon}
        {children}
        {iconRight}
      </button>
    ),
);

Button.displayName = "Button";
