import React, { FC, useCallback, useState } from "react";
import { Flex, Image, Modal } from "antd";
import classNames from "classnames";
import { MdPortrait } from "react-icons/md";
import { Text } from "../../../../ui/Text/Text";
import { Button } from "../../../../ui/Button/Button";
import { DropZone, DropZoneFile } from "../../../../ui/DropZone/DropZone";
import styles from "./AvatarModal.module.scss";
import { usePageSize } from "../../../../hooks/usePageSize";

interface IAvatarModalProps {
  open: boolean;
  onClose: () => void;
  onEditPhoto: (file: File) => void;
}

const AvatarModal: FC<IAvatarModalProps> = ({ open, onClose, onEditPhoto }) => {
  const { isFromTabletVertical } = usePageSize();
  const [fileList, setFileList] = useState<DropZoneFile[]>([]);
  const file = fileList?.[0]?.originFileObj;

  const onClear = useCallback(() => {
    setFileList([]);
  }, []);

  return (
    <Modal
      title={<Text variant="header-6">Загрузка фотографии</Text>}
      open={open}
      onCancel={onClose}
      footer={[
        file && (
          <Button key="clear" type="flat_secondary" size="s" onClick={onClear}>
            Очистить
          </Button>
        ),
        <Button
          key="submit"
          type="primary"
          size="s"
          onClick={() => onEditPhoto(file)}
        >
          Применить
        </Button>,
      ]}
    >
      <DropZone
        accept=".jpg,.jpeg,.png"
        multiple={false}
        onChange={setFileList}
      >
        {file ? (
          <Flex align="center" vertical gap={8} className={styles.photoZone}>
            <Flex align="center" vertical gap={4}>
              <Image
                rootClassName={styles.photo}
                preview={false}
                src={URL.createObjectURL(file)}
                height={126}
                width={126}
              />
            </Flex>
            <Flex>
              <Button type="flat" size="s">
                <Text variant="body-4" color="accent">
                  Заменить
                </Text>
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Flex
            align="center"
            vertical
            gap={32}
            className={classNames(styles.zone, styles.dropZone)}
          >
            <Flex align="center" vertical gap={24}>
              <MdPortrait size={48} className={styles.icon} />
              <Text color="aqua" variant="body-6">
                Загрузите {isFromTabletVertical ? " или перетащите" : ""} файл в
                формате: jpg, png
              </Text>
            </Flex>
            <Button type="flat">
              <Text variant="body-4" color="accent">
                Выбрать файл
              </Text>
            </Button>
          </Flex>
        )}
      </DropZone>
    </Modal>
  );
};

export default AvatarModal;
