import { MdSnippetFolder, MdAnalytics, MdPolicy } from "react-icons/md";
// import { IoDocumentText } from "react-icons/io5";
import { ENavMenuItem, MAIN_TAB_QUERY, MainTab } from "@pages/Main/constants";
import { AppRoute } from "src/app/routes/constants";
import { ReactNode } from "react";
import { generatePath } from "react-router-dom";
import { IoDocumentText } from "react-icons/io5";
import { env } from "src/utils/env";
import { MenuSubItem } from "./types";

export enum MenuGroup {
  Checking = "checking",
  Cabinet = "cabinet",
  Organization = "organization",
  Info = "info",
}

type MenuItem = {
  key: string;
  title: string;
  meta: string;
  icon?: ReactNode;
} & (
  | {
      href: string;
      children?: never;
    }
  | {
      children: MenuSubItem[];
      href?: never;
    }
);

interface GetMenuItemsProps {
  group_id?: number;
  user_id?: number;
}

const isOdyssey = env.REACT_APP_THEME !== "kretchet";

export const getMenuItems = ({
  group_id,
  user_id,
}: GetMenuItemsProps): MenuItem[] => {
  const menuItems: MenuItem[] = [
    {
      key: MenuGroup.Checking,
      title: "Проверка",
      meta: "проверка",
      icon: <MdPolicy size={24} />,
      children: [
        // {
        //   key: MainTab.Dossier,
        //   title: 'Полное досье 🔥',
        //   href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Dossier}`,
        // },
        {
          key: MainTab.Fio,
          title: "По ФИО",
          meta: "по фио,by fio",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Fio}`,
        },
        {
          key: MainTab.Phone,
          title: "По телефону",
          meta: "по телефону,by phone",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Phone}`,
        },
        {
          key: MainTab.Address,
          title: "По адресу",
          meta: "по адресу,by address",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Address}`,
        },
        {
          key: MainTab.InnOrSnils,
          title: "По ИНН или СНИЛС",
          meta: "по ИНН или СНИЛС,по СНИЛС или ИНН,by inn or snils,by snils or inn",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.InnOrSnils}`,
        },
        {
          key: MainTab.Passport,
          title: "По паспорту",
          meta: "по паспорту,by passport",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Passport}`,
        },
        {
          key: MainTab.Auto,
          title: "По номеру авто",
          meta: "по номеру авто,by auto number",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Auto}`,
        },
        {
          key: MainTab.DriverLicense,
          title: "По ВУ",
          meta: "по ВУ,by driver license",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.DriverLicense}`,
        },
        {
          key: MainTab.Company,
          title: "По юр. лицам",
          meta: "по юр. лицам,by company",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Company}`,
        },
        {
          key: MainTab.Photo,
          title: "По фото",
          meta: "по фото,by photo",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Photo}`,
        },
        {
          key: MainTab.CreditRating,
          title: "Кредитный рейтинг",
          meta: "кредитный рейтинг,credit rating",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.CreditRating}`,
        },
        {
          key: MainTab.Scoring,
          title: "Скоринг",
          meta: "скоринг,scoring",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Scoring}`,
        },
        {
          key: MainTab.Info,
          title: "По ключевому слову",
          meta: "по ключевому слову,by keyword",
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Info}`,
        },
      ],
    },
    {
      key: MenuGroup.Cabinet,
      title: "Кабинет",
      meta: "кабинет,cabinet",
      icon: <MdAnalytics size={24} />,
      children: [
        {
          key: ENavMenuItem.Analytics,
          title: "Аналитика",
          meta: "аналитика,analytics",
          href: generatePath(AppRoute.Analytics, {
            group_id: String(group_id),
            user_id: String(user_id),
          }),
          disabled: !group_id || !user_id,
        },
        {
          key: ENavMenuItem.History,
          title: "История проверок",
          meta: "история проверок,history",
          href: AppRoute.History,
        },
        // TODO: Uncomment when it's ready
        // {
        //   key: ENavMenuItem.Employees,
        //   title: "Мониторинг",
        //   meta: "сотрудники,employees",
        //   href: AppRoute.Employees,
        // },
      ],
    },
    {
      key: MenuGroup.Organization,
      title: "Организация",
      meta: "организация,organization",
      icon: <MdSnippetFolder size={24} />,
      children: [
        {
          key: ENavMenuItem.Action,
          title: "Журнал действий",
          meta: "действия,actions",
          href: AppRoute.Action,
        },

        {
          key: ENavMenuItem.Accesses,
          title: "Доступы",
          meta: "доступы,accesses",
          href: generatePath(AppRoute.Accesses, {
            group_id: String(group_id),
          }),
          disabled: !group_id,
        },
        {
          key: ENavMenuItem.Support,
          title: "Поддержка",
          meta: "поддержка,support",
          href: AppRoute.Support,
        },
      ],
    },
    // TODO remove the check when the content for kretchet appears
    ...(isOdyssey
      ? [
          {
            key: MenuGroup.Info,
            title: "Инфо",
            meta: "инфо,info,информация,information",
            icon: <IoDocumentText size={24} />,
            children: [],
          },
        ]
      : []),
    // { key: "20", title: 'Обновления' },
  ];

  if (isOdyssey) {
    const infoGroup = menuItems.find(
      (menuItem) => menuItem.key === MenuGroup.Info,
    );

    if (infoGroup) {
      infoGroup.children?.unshift(
        {
          key: "apiDocs",
          title: "API-документация",
          meta: "документация,api",
          href: "https://docs.odyssey-search.ru",
        },
        {
          key: ENavMenuItem.Contacts,
          title: "Контакты",
          meta: "контакты,contacts",
          href: AppRoute.Contacts,
        },
      );
    }

    menuItems.push({
      key: ENavMenuItem.Telegram,
      title: "Телеграм бот",
      meta: "телеграм бот,telegram bot,telegram,bot",
      href: AppRoute.Telegram,
    });
  }

  return menuItems;
};

interface GetFlatMenuItemsProps {
  group_id?: number;
  user_id?: number;
}

interface FlatMenuItem {
  key: string;
  title: string;
  meta: string;
  href: string;
  icon?: ReactNode;
}

export const getFlatMenuItems = ({
  group_id,
  user_id,
}: GetFlatMenuItemsProps): FlatMenuItem[] => {
  const tree = getMenuItems({ group_id, user_id });

  return tree
    .map((item) => {
      if (item.children) {
        return item.children.map((subItem) => ({
          ...subItem,
          icon: item.icon,
        }));
      }

      return item;
    })
    .flat();
};
