import dayjs from "dayjs";

export function toHumanReadableDate(
  isoDateStr: string,
  pattern = "DD.MM.YYYY в HH:mm",
): string {
  try {
    const date = dayjs(isoDateStr);

    return date.format(pattern);
  } catch {
    return "";
  }
}
