import { useCallback } from "react";
import { Switch } from "antd";
import { IoMoonOutline } from "react-icons/io5";
import { TbSunFilled } from "react-icons/tb";
import classNames from "classnames";
import { useAppTheme } from "@processes/App/components/AppTheme/useAppTheme";
import s from "./MainLayoutThemeSwitcher.module.scss";

export const MainLayoutThemeSwitcher = () => {
  const [theme, setTheme] = useAppTheme();

  const toggleTheme = useCallback(
    (checked: boolean) => {
      setTheme(checked ? "dark" : "light");
    },
    [setTheme],
  );

  const checked = theme === "dark";

  return (
    <Switch
      checkedChildren={<IoMoonOutline size={20} />}
      unCheckedChildren={<TbSunFilled size={20} />}
      onChange={toggleTheme}
      checked={checked}
      className={classNames(s.toggle, { [s.toggle_checked]: checked })}
      title="theme-switcher"
    />
  );
};
