import { NotificationInstance } from "antd/es/notification/interface";
import axios from "axios";
import _ from "lodash";

interface HandleBackendErrorsProps {
  api: NotificationInstance;
  error: unknown;
  message4xx: string;
  message5xx?: string;
}

export const handleBackendErrors = ({
  api,
  error,
  message4xx,
  message5xx = "Произошла непредвиденная ошибка",
}: HandleBackendErrorsProps): boolean => {
  if (!axios.isAxiosError(error)) {
    return false;
  }

  const status = error.response?.status;
  if (!status) {
    return false;
  }

  const description = JSON.stringify(
    _.pick(error, ["message", "status", "code"]),
    null,
    2,
  );

  if (error.code === "ERR_NETWORK" || (status >= 500 && status < 600)) {
    api.error({
      message: message5xx,
      description,
    });

    return true;
  }

  if (status >= 400 && status < 500) {
    api.error({
      message: message4xx,
      description,
    });

    return true;
  }

  return false;
};
